import React from 'react';
import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';
import withInputMethods from 'components/formik/withInputMethods';

export const inputCss = css`
  ${props =>
    props.primary &&
    css`
      border: 2px solid ${props => props.theme.colors.citrusZing};
      color: ${props => props.theme.colors.arsenic};
      height: ${props => props.height || '46px'};
      line-height: 46px;
      padding: 0 15px;
      width: 100%;
      border-radius: 6px;
      font-weight: 400;
      font-size: 18px;
    `}
  ${props =>
    props.primary &&
    props.invalid &&
    css`
      border: 2px solid ${props => props.theme.colors[invalidColor]};
    `}
`;
export const invalidColor = 'vividAuburn';
export const Root = styled.div`
  ${props =>
    props.primary &&
    css`
      margin-bottom: 36px;
    `}
`;
export const Label = styled.label`
  ${props =>
    props.hidden &&
    css`
      display: none;
    `}
  ${props =>
    props.primary &&
    css`
      font-size: 18px;
      line-height: 18px;
    `}
    
      ${props =>
        props.primary &&
        props.invalid &&
        css`
          color: ${props => props.theme.colors[invalidColor]};
        `}
`;
export const ErrorMessageContainer = styled.div`
  position: relative;
`;
export const ErrorMessage = styled.div`
  font-size: 14px;
  color: ${props => props.theme.colors[invalidColor]};
  position: absolute;
  height: 20px;
`;
const InputWrapper = styled.input`
  ${inputCss}
`;

const Input = props => {
  const {
    value,
    id,
    label,
    placeholder,
    readonly,
    disabled,
    type,
    autoComplete,
    primary,
    name,
    containerClassName,
    inputClassName,
    isInvalid,
    handleFocus,
    handleChange,
    handleBlur,
    errorMessage,
  } = props;

  return (
    <Root className={containerClassName} primary={primary}>
      <Label
        htmlFor={id}
        hidden={!label || !primary}
        invalid={isInvalid}
        primary={primary}
      >
        {label}
      </Label>
      <InputWrapper
        primary={primary}
        invalid={isInvalid}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        name={name}
        placeholder={placeholder}
        id={id}
        type={type}
        value={value}
        className={inputClassName}
        autoComplete={autoComplete}
        readOnly={readonly}
        disabled={disabled}
      />
      {label && !primary && <Label htmlFor={id}>{label}</Label>}
      {errorMessage}
    </Root>
  );
};

Input.defaultProps = {
  onBlur: undefined,
  value: '',
  formatter: '',
  className: '',
  autoComplete: 'off',
  placeholder: '',
  readonly: false,
  disabled: false,
  primary: false,
  type: 'text',
};

Input.propTypes = {
  errorMessage: PropTypes.element,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleFocus: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(['text', 'email', 'password', 'number']).isRequired,
  formatter: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  primary: PropTypes.bool,
  containerClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  isInvalid: PropTypes.bool,
};

export default withInputMethods(Input);
