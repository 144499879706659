import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Root, Label, inputCss} from 'components/formik/input';
import withInputMethods from 'components/formik/withInputMethods';

const TextAreaWrapper = styled.textarea`
  ${inputCss}
`;

const TextArea = props => {
  const {
    name,
    containerClassName,
    inputClassName,
    isInvalid,
    rows,
    height,
    value,
    id,
    label,
    placeholder,
    readonly,
    disabled,
    autoComplete,
    primary,
    handleChange,
    handleFocus,
    handleBlur,
    errorMessage,
  } = props;

  return (
    <Root className={containerClassName} primary={primary}>
      <Label
        htmlFor={id}
        hidden={!label || !primary}
        invalid={isInvalid}
        primary={primary}
      >
        {label}
      </Label>
      <TextAreaWrapper
        height={height}
        rows={rows}
        primary={primary}
        invalid={isInvalid}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        name={name}
        placeholder={placeholder}
        id={id}
        value={value}
        className={inputClassName}
        autoComplete={autoComplete}
        readOnly={readonly}
        disabled={disabled}
      />
      {label && !primary && <Label htmlFor={id}>{label}</Label>}
      {errorMessage}
    </Root>
  );
};

TextArea.defaultProps = {
  rows: 5,
  height: '96px',
  onBlur: undefined,
  value: '',
  formatter: '',
  className: '',
  autoComplete: 'off',
  placeholder: '',
  readonly: false,
  disabled: false,
  primary: false,
};

TextArea.propTypes = {
  rows: PropTypes.number,
  height: PropTypes.string,
  errorMessage: PropTypes.element,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleFocus: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formatter: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  primary: PropTypes.bool,
  containerClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  isInvalid: PropTypes.bool,
};

export default withInputMethods(TextArea);
