import React, {Component} from 'react';
import {connect} from 'formik';
import {ErrorMessage, ErrorMessageContainer} from 'components/formik/input';
import PropTypes from 'prop-types';

const withInputMethods = WrappedComponent => {
  class HOC extends Component {
    state = {
      focus: false,
    };

    get name() {
      const {name, id} = this.props;
      return name || id;
    }
    get containerClassName() {
      const {value, className, placeholder, primary} = this.props;

      if (primary) {
        return '';
      }

      return `form-group${
        this.state.focus || value || placeholder ? ' do-float' : ''
      } ${className}`;
    }
    get inputClassName() {
      const {primary} = this.props;
      let className = `${this.isInvalid ? ' is-invalid' : ''}`;
      if (primary) {
        return className;
      }
      return `form-control${className}`;
    }
    get isInvalid() {
      const {
        formik: {errors, touched},
      } = this.props;
      const name = this.name;

      return errors && errors[name] && touched && touched[name];
    }
    get errorMessage() {
      const {
        primary,
        formik: {errors},
      } = this.props;
      const name = this.name;

      if (!this.isInvalid) {
        return null;
      }

      if (primary) {
        return (
          <ErrorMessageContainer>
            <ErrorMessage>{errors[name]}</ErrorMessage>
          </ErrorMessageContainer>
        );
      }
      return <div className="invalid-feedback">{errors[name]}</div>;
    }

    handleChange = event => {
      const {formatter, formik, name} = this.props;
      const {value} = event.target;

      if (formatter === 'float') {
        formik.setFieldValue(name, value.replace(/[^\d.-]/g, ''));
        return;
      }

      if (formatter === 'int') {
        formik.setFieldValue(name, value.replace(/[^\d]/g, ''));
        return;
      }

      formik.handleChange(event);
    };

    handleFocus = () => {
      this.setState({focus: true});
    };

    handleBlur = event => {
      this.props.formik.handleBlur(event);
      this.setState({focus: false});
    };

    render() {
      const {
        handleBlur,
        handleFocus,
        handleChange,
        name,
        containerClassName,
        inputClassName,
        isInvalid,
        errorMessage,
      } = this;

      return (
        <WrappedComponent
          {...{
            ...this.props,
            focus: this.state.focus,
            handleBlur,
            handleFocus,
            handleChange,
            name,
            containerClassName,
            inputClassName,
            isInvalid,
            errorMessage,
          }}
        />
      );
    }
  }

  HOC.propTypes = {
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.oneOf(['text', 'email', 'password', 'number']),
    formatter: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    autoComplete: PropTypes.string,
    placeholder: PropTypes.string,
    readonly: PropTypes.bool,
    disabled: PropTypes.bool,
    primary: PropTypes.bool,
    formik: PropTypes.shape({
      handleBlur: PropTypes.func.isRequired,
      handleChange: PropTypes.func.isRequired,
      setFieldValue: PropTypes.func.isRequired,
      errors: PropTypes.object.isRequired,
      touched: PropTypes.object.isRequired,
    }),
  };

  return connect(HOC);
};

export default withInputMethods;
