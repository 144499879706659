import {string, object, ref, number} from 'yup';

export const shape = validators => object().shape(validators);

export const stringValidator = fieldName =>
  string()
    .min(2, `${fieldName} field does not meet the minimum length of two characters`)
    .max(50, `${fieldName} field exceeds the maximum length of 50 characters`)
    .required(`${fieldName} field is required`);

export const dateValidator = fieldName =>
  string()
    .test({
      name: 'date',
      message: `Please enter a valid date in the format dd/mm/yyyy for field ${fieldName.toLowerCase()}`,
      test: value =>
        `${value}`.match(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i),
    })
    .required(`${fieldName} field is required`);

export const integerValidator = fieldName =>
  number()
    .integer(`Please enter a whole number for ${fieldName.toLowerCase()} field`)
    .required(`${fieldName} field is required`);

export const currencyAmountValidator = fieldName =>
  number()
    .test({
      name: 'is-amount',
      message: `${fieldName} field should be a valid amount i.e. 0.75, 3.50, 10.50`,
      test: value => `${value}`.match(/^[0-9]+(\.[0-9]{1,2})?$/gm),
    })
    .required(`${fieldName} field is required`);

const validators = {
  company: stringValidator('Company'),
  date: stringValidator('Date'),
  firstName: stringValidator('First name'),
  lastName: stringValidator('Last name'),
  email: string()
    .email('Please enter a valid email address')
    .required('Email is required'),
  password: string()
    .min(8, 'Please enter a password between 8 and 20 characters')
    .max(20, 'Please enter a password between 8 and 20 characters')
    .required('Password is required'),
  retypePassword: string()
    .min(8, 'Please enter a password between 8 and 20 characters')
    .max(20, 'Please enter a password between 8 and 20 characters')
    .oneOf([ref('password'), null], 'Please ensure the two passwords match')
    .required('Retype password is required'),
};

export const profileSchema = shape({});

export default validators;
